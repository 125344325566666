<template>
  <AMultiSelect v-model="selected" v-bind="$attrs" v-on="$listeners" :options="cities" />
</template>

<script>
export default {
  name: 'ACitySelect',
  inheritAttrs: true,
  watch: {
    value(val) {
      this.selected = val
    }
    /*async value(to) {
      this.selected = to
      await this.$nextTick()
        this.$forceUpdate()
    }*/
  },
  props: {
    value: {
      type: [String, Number, Object]
    }
  },
  /*computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event),
        change: event => this.$emit('change', event)
        //select: event => this.emit('select', event)
      }
    }
  },*/
  data() {
    return {
      cities: [],
      selected: this.value
    }
  },
  created() {
    // TODO: use Vuex
    this.$http
      .service('GET', 'list-cities')
      .then(response => {
        this.cities = response.data.cities
      })
      .catch(error => {
        console.error(error)
      })
  }
}
</script>
